<!--  -->
<template>
  <div class="container">
    <div class="main-title">‘新科技，创未来’新品发布会暨毕棚沟旅游’免责声明书</div>
    <div class="remark">主办方特别提醒：请所有参加活动的人员在报名前务必认真、仔细阅读以下声明，在您签名后即视为您已阅读、理解并自愿遵守以下免责声明的全部内容。</div>
    <div class="pro-item">
      <p>本人作为一名身体健康的成年人，具有完全民事行为能力，我本人、我的法定代理人以及任何可能代表我提起赔偿请求或诉讼的人作出如下声明：</p>
      <p>一、本人已知悉本次活动的全部行程，本人身体健康，无任何不适宜参加本次活动的身体状况，经审慎考虑，自愿（或携带未成年人）参加 四川蜜蛋科技有限公司（以下简称“主办方”）组织的活动，并基于本人的真实意思表示作出本声明和保证。
      </p>
      <p>二、在此次会议活动中，如本人（及携带的未成年人）须遵从主办方工作人员的统一指引和管理，不得擅自擅自改变行程。</p>
      <p>
        三、本人已知悉活动参加者众多，存在一定的安全风险，这些风险所导致的本人（及携带的未成年人）人身、财产及/或导致第三方的人身、财产上的损失将由本人自行承担，主办方无需就本人（及携带的未成年人）的任何安全事故或财产损失承担任何经济责任及/或法律责任。
      </p>
      <p>四、本人同意：主办方有权根据活动的实际情况，要求本人退出活动。本人应遵守主办方的安排的退出行程。</p>
      <p>五、一旦报名成功，则不允许取消或者退换名额。</p>
      <p>本人确认紧急联系方式如下（以下填能随时联系到的亲属）：</p>
      <p>联系人姓名： 联系电话：</p>
      <p>联系地址： 与本人的关系：</p>
      <p>本声明自本人签字之日起生效，本人已清楚阅读并充分理解和同意免责声明上的所有条款。</p>
      <p>签名：_______________ 身份证号：________________</p>
      <p>（18岁以下活动参加者的监护人同时签名） </p>
      <p>日期：_______________ 联系电话：_______________</p>
    </div>
    <van-divider />
    <div class="main-title">202309成都 新科技，创未来新品发布会暨毕棚沟旅游报名须知</div>
    <div class="pro-item">
      <p>亲爱的蜜蛋宝宝：</p>
      <p>报名参与‘新科技 创未来新品发布会暨毕棚沟旅游报名须知’（以下简称“本活动”）前，请认真阅读以下须知：</p>
      <p>1.您承诺在报名之前14天内无感冒、发烧等其他身体不适，确保出行前身体健康，适合出行。</p>
      <p>2.孕妇、70岁及以上老人、18岁以下的未成年人参加本次行程须有一名陪同人员参与本次活动，承担相关安全责任。并须签订免责协议。</p>
      <p>3.活动期间，须承诺全程配合主办方的安排及流程，遵守相关管理规定，不得擅自行动，由此带来的不良后果，将由本人承担。</p>
      <p>您已完全阅读以上内容，确认符合以上须知条件、保证做到以上承诺，自愿报名参与活动。若报名后，因自身原因导致未能参与活动的，活动费用及相关权益不退不换。</p>
    </div>
    <div class="agree-btn">
      <van-button round color="#E0BD90" @click="onClose">阅读并同意《免责声明》《报名安全注意事项》</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      protocolTxt: ""
    }
  },
  created() {
    //获取旅游协议信息
    // this.getInformationById(12);
  },
  methods: {
    getInformationById(typeId) {
      this.get("/Base/PublicTools/GetAgreementManage", { typeId }, 2).then(res => {
        if (res.code === 1) {
          this.protocolTxt = res.response.content
        }
      })
    },
    onClose() {
      this.$emit("close")
    }
  }
}
</script>
<style lang='scss' scoped>
.container {
  color: #666;
  font-size: 12px;
  // padding: 11px 15px;

  .main-title {
    padding: 11px 15px 0;
    font-size: 18px;
    font-weight: bold;
  }

  .remark {
    padding: 11px 15px 0;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: underline;
    text-indent: 16px;
  }

  .pro-item {
    padding: 11px 15px;
    letter-spacing: 1px;
    line-height: 20px;
    // border-bottom: 1px solid #999;

    // &:last-child {
    //   border-bottom: none;
    // }
  }

  .agree-btn {
    text-align: center;
    padding: 20px 0 30px;

    .van-button {
      font-size: 14px;
      box-shadow: 0 4px 0 #cf9d5c;
    }
  }
}
</style>